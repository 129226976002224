.carousel-card {
    width:  100%;
    height:  300px;
    display:  flex;
    font-size:  15px;
    flex-direction:  row;

    &__main-info {
        width: 45%;
        display: flex;
        flex-direction: column;
        padding: 0px 20px 20px;
        height: 100%;
        &_header {
            border-bottom: 4px solid rgb(48, 45, 77);

            padding: 10px;
            font-size: 25px;
            font-weight: 700;
            min-height: 50px;
        }
        &_type {
            height: 20px;
        }
        &_description {
            padding: 20px;
            font-size: 17px;
            text-align: justify;
            min-height: 150px;
        }
        &_button {
            display: flex;
            justify-content: start;
            padding: 10px
        }
    }
    &__blocks {
        // border-left: 4px solid rgb(48, 45, 77);

        // border-right: 4px solid rgb(48, 45, 77);
        // box-shadow: rgba(0, 0, 0, 0.7) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        width: 15%;   
        padding: 5px;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    &__block {
        box-shadow: rgba(0, 0, 0, 0.7) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        border-bottom: 1px solid rgb(48, 45, 77);
        width :100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    &__description {
        padding: 20px;
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        &_header {
            border-bottom: 4px solid rgb(48, 45, 77);
            min-height: 50px;
            text-align: start;
            padding: 10px;
            font-weight: 700;
            font-size: 25px;
        }
        &_container {
            min-height: 200px;
            padding-top: 40px;
            margin: 0px;
        }
        &_section {
            margin: 0px;
            height: 30px;
            margin-top: 10px;
        }
    }
}