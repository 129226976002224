.contact-form {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-color);
    color: white;
    padding: 10px;


    &__header {
        margin: 10px 20px 0 20px;
        font-size: 18px;
        position: relative;
        &::after {
            content: '';
            width: 100%;
            height: 2%;
            background-color: white;
            bottom: -10px;
            left: 0px;
            position: absolute;
        }
    }
    &__container {
        padding: 10px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        justify-content: space-between;     
        align-items: center; 
    }
    &__container-second {
        padding: 10px 10px;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 300px;
            justify-content: center;
            align-items: center;
            &_text {
                text-align: center;
                font-size: 20px;
            }
            &_image {
                margin: 20px;
                    height: 40px;
                    width: 40px;
                    background-image: url('../../images/registration-icon.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
    }


    &__field {
        width: 90%;
        margin-top: 40px;
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        &.email {
            width: 48%;
        }
        &.phone {
            width: 48%;
        }
    }
    &__input {
        font-size: 20px;;
        height: 40px;
        width: 100%;
        
    }

    &__label {
        position: absolute;
        top: -30px;
        font-weight: 700;
    }

    &__button {
        margin: 0px auto;
        width: 48%;
        height: 30px;
        background-color: var(--main-color);
        cursor: pointer;
        color: white;
        border: 0px;
        outline: none;
    }

    @include for-extra-small {
        border-radius: 0px;
        color: white;
        width: 100%;
        min-height: 300px;
        padding: 0px;
        margin: 0px;
        background-color: var(--main-color);
    
    &__container {
        padding: 10px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        justify-content: space-between; 
        align-items: center;
    }
        
    &__field {
        margin-bottom: 20px;
        width: 100%;
        &.email {
            width: 48%;
        }
        &.phone {
            width: 48%;
        }
    }
    
    &__input {
        height: 30px;
    &#message {
        height: 50px
    }
        }

    &__container {
        }
    }

}