$bg: #1a1f25;
$button: #5a5a59;
$border: #22272d;

.login-form {
  width: 400px;

  &_message {
    padding: 10px;
    font-size: 12px;
    color: brown;
  }

  &__checkbox {
    padding: 10px 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    &_container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_label {
      margin-right: 10px;
    }
  }

  &__wrapper {
    width: 300px;
    margin: 12% auto;
  }

  &__header {
    width: 400px;
    height: 60px;
    &_sign-in {
      float: left;
      color: linear-gradient(to right, #0f0c29, #302b63, #24243e);
      font-size: 1.3em;
    }

    &_button {
      background: var(--radial-gradient);
      color: white;
      float: right;
      border: 1px solid $border;
      padding: 7px 15px;
      border-radius: 3px;
      font-size: 0.8em;

      cursor: pointer;
      &:hover {
        background-color: lightgray;
        transition: linear 0.1s;
      }
    }
  }
  &__label {
    position: absolute;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    padding: 7px;
    left: 5px;

    &:after {
      width: 1px;
      height: 30px;
      position: absolute;
      background: $border;
      top: 0px;
      left: 100%;
    }
    &.user {
      // background-image: url('./../../../Styles/images/user_Icon.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
    &.lock {
      // background-image: url('./../../../Styles/images/lock.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__inputs {
    position: relative;
  }
  &__input {
    width: 100%;
    padding: 5px;
    height: 40px;
    border-radius: 3px;
    margin: 5px 0;
    outline: none;
    background: transparent;
    border: 2px solid gray;
    padding-left: 45px;
    color: $button;

    &:focus {
      border: 1px solid lightgray;
      box-shadow: none;
    }
  }

  &__submit {
    width: 100%;
    padding: 5px;
    height: 40px;
    border-radius: 3px;
    margin: 5px 0;
    outline: none;
    background: var(--radial-gradient);
    border: none;
    color: white;
    text-align: center;
    font-size: 0.8em;
    cursor: pointer;
  }

  &__forgot-label {
    font-size: 0.8em;
    color: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    margin-top: 10px;
    padding-top: 10px;
    float: right;
    padding: 3px 0;
  }

  @include for-extra-small {
    z-index: 3;
    &__header {
      width: 315px;
    }
    width: 90%;
  }
}
