$sm: 1024px;

@mixin for-small {
  @media screen and (max-width: 1000px) and (min-width: 600px) {
    @content;
  }
}
@mixin for-large {
  @media screen and (min-width: 800px ) and (max-width: 1000px) {
    @content;
  }
}

@mixin for-desktop {
  @media screen and (min-width: $sm) {
    @content;
  }
}

@mixin for-extra-small {
  @media screen and (max-width: 1000px) {
    @content;
  }
}