.cabinet {
    min-height: 100vh;
    display: flex;
    flex-direction: column; 
   
    &__container {
        display: flex;
        min-height: 500px;
        flex-direction: row;
        padding: 50px;
        flex-flow: wrap;
        justify-content: space-between;

    }
    &__balance {
        width: 30%;
        min-height: 200px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

    }
    &__personal-data {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: space-around;
        width: 40%;
        height: 200px;

        &_item {
            margin-top: 20px;
        }

    }
    &__sertificates {
        padding-top: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        h1 {
            text-align: center;
            font-size: 20px;
        }
        &_container {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);  
            gap: 20px;  
            min-height: 200px;
            padding: 20px;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-grow: 1;
        margin-top: 20px;
        width: 40%;
        min-height: 300px;
    }
    &__sertificate {
    
        border: 0.2px solid gainsboro;
        padding: 20px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @include for-extra-small {

        &__container {
        padding: 20px;
        flex-direction: column;

    }
    &__balance {
        width: 100%;

    }

    &__personal-data {
        width: 100%;
    }
    &__sertificates {
        width: 100%;
        &_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 200px;
            padding: 20px;
        }
    }
    }

}