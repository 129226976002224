@import './Pages/article.scss';

.articles {
    &__container{
    width: 100%;
    min-height: 100vh;}
    &__header {
        width: 100%;
        height: 400px;
        text-align: start;

        display: flex;
        flex-direction: row;
        &_text {
            width: 40%;
            padding: 150px 50px 50px;
        }
    &_links {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;


    }
    &_link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        color: white;
        &:hover {
            cursor: pointer; 
        }
        min-width: 200px;
        max-width: 800px;
        max-height: 60px;
        background: var(--linear-gradient);
        &:first-child {margin-left: -100px}
        &:nth-child(3) {
            margin-left: 100px;
        }
        
     }
    }
    &__main {
        min-height: 500px;
        width: 100%;
        display: flex;
        flex-direction: column;
        &_header {
            width: 100%;
            text-align: center;
        }
        &_container {
            width: 100%;
            padding: 50px;
        }
        &_link {
            margin-top: 5px;
            width: 100%;
            margin-top: 20px;
            color: white;
            padding: 10px 30px 30px;
            background: var(--linear-gradient);
            &_header {

                font-size: 22px;
            }
        }
    }

 @include for-extra-small {

    &__main {
        &_header {
            padding: 0px 20px;
        }
        &_container {
            padding: 20px 20px;
        }
    }
    &__header {
        height: 500px;
        flex-direction: column;

        &_text { 
            width: 100%;
            padding: 40px;
        }
        &_links {
            width: 100%;
            padding: 10px
        }
        &_link {
            width: 70%;
            margin-top: 10px;
            padding: 10px 10px;
            text-align: center;
            &:first-child {
                margin-left: 0px
            }
            
            &:nth-child(2) {
                margin-left: 50px;
            }
            &:nth-child(3) {
                margin-left: auto;
            }
        }
    }

 }
}