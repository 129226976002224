$border: gray;
$button: linear-gradient(to right, #0f0c29, #302b63, #24243e);

.test-form {
  width: 400px;

  &__wrapper {
    width: 600px;
    margin: 12% auto;

  }

  &__header {
    width: 400px;
    height: 60px;

  
    &-title {
      float: left;
      color: $button;
      font-size: 1.3em;
    }

    &-button {
      float: right;
      color: $button;
      border: 1px solid $border;
      padding: 7px 15px;
      border-radius: 3px;
      font-size: 0.8em;
      cursor: pointer;

      &:hover {
        background-color: lightgray;
        transition: linear 0.1s;
      }
    }
  }

  &__inputs {
    position: relative;
  }

  &__question {
    font-size: 1.2em;
    margin-top: 20px;
  }

  &__options {
    list-style-type: none;
    padding: 0;

    li {
      margin: 10px 0;
    }
  }

  &__option {
    width: 100%;
    padding: 5px;
    min-height: 40px;
    border-radius: 3px;
    outline: none;
    background: transparent;
    border: 2px solid gray;
    padding-left: 45px;
    color: $button;
    cursor: pointer;

    &:focus {
      border: 1px solid lightgray;

      box-shadow: none;
    }
  }

  @include for-extra-small {
    &__wrapper {
      width: 360px;
    }
  }
}

.selected {
  background-color: lemonchiffon;
  border: 1px solid lightgray; }
  