.test {
    width: 100%;
    min-height: 200px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    color: white;


    &__image {
        height: 100%;
        width: 20%;
        // border: 1px solid gray;
        background-image: url('../../../../images/cat_test.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__main {
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
        rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
        rgba(0, 0, 0, 0.07) 0px 16px 16px;    
        width: 80%;
        min-height: 200px;
        padding: 20px;
        color: rgb(48, 45, 77);

        &_header {
            font-weight: 800;
            font-size: 4vh;
            width: 70%;
            min-height: 30%;
        }
        &_description {
            margin-top: 20px;
            width: 100%;
            height: 50%;

        }
    }
    &__buttons {
        width: 350px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    &__info {
        color:rgb(48, 45, 77);
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
          
    }

    @include for-extra-small {
        flex-direction: column;
        min-height: 200px;;
        &__main {
            width: 100%;
            border: 1px solid var(--main-color);
            &_header {
                background-color: var(--main-color);
                color: white;    
                width: 100%;
                text-align: justify;
                font-size: 17px;
                padding: 10px;
                text-align: center;
                margin: 0px;


            }

            &_description {
                text-align: center;
            }
        }
        &__info {
            padding: 20px 5px;
            width: 100%;
            font-size: 15px;
            display: flex;
            flex-direction: row;
            &_time {
                text-align: center;
            }
            &_level {
                text-align: center;
            }
            &_price {
                text-align: center;
            }
        }
    }
}