@import '../TestPage/testForm.scss';

.testPage {

    width: 100%;
    min-height: 101vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &__container {
        margin-top: 50px;
        margin-bottom: 50px;
        height: 400px;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__header {
        &_container {
            height: 100%;
            padding: 50px;
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

        }

        ;

        &_text {
            width: 100%;
            font-size: 30px;
            min-height: 70px;
            margin-top: 10px;
            padding-top: 20px;
        }

    }
    &__button {
        outline: none;
        border: none;
        cursor: pointer;
        height: 40px;
        color: white;
        background-color: var(--main-color)
    };

    &__cards {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 50px
    }

    &__card {
        background-color: var(--main-color);
        color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    &__warning {
        &_container {
            margin-bottom: 40px;
            ;
            width: 80%;
        }

        ;

        &_link {
            font-size: 12px;
            padding: 10px 0px;

        }

        &_button {
            text-align: start;
            width: 200px;
            font-size: 12px;

            &:after {
                width: 30px;
                height: 30px;
                content: '⇲';
                margin-left: 20px;
            }

        }

        width: 80%;
        display: flex;
        font-size: 12px;
        flex-direction: column;
        height: 150px;
        justify-content: space-around;
        padding: 10px;

    }


    @include for-extra-small {

        min-height: 100vh;

        &__container {
            width: 100%;
            height: 800px;
            flex-direction: column;
            margin-top: 0px;
        }

        &__header {
            &_container {
                height: 450px;
                width: 100%;
                padding: 10px 40px;
            }

            &_text {
                justify-content: center;
                align-items: center;
                text-align: center;
                color: white;
                font-size: 25px;
                padding: 10px;
                background-color: var(--main-color);
            }
            &_description {
                padding: 5px;
                text-align: center;

            } 
        }


        &__cards {
            width: 100%;
            height: 400px;
            padding: 20px;
        }

        &__card {
            padding: 15px;
            &:nth-child(3) {
                position: relative;
                left: 55%;
            }
        }

        &__warning {
            background-color: var(--main-color);
            height: 300px;
            width: 100%;
            color: white;

            &_container {
                width: 100%;
            }

            &_item {
                width: 100%;
                margin-top: 10px;

            }
        }


    }
}