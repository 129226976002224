.pdf {
    width: 100%;
    height: 100%;
    background-color: red;
    // background-image: url('./sertficate.jpg');
    // background-size: cover;
    // background-repeat: no-repeat;

    &__button {
        background-color: var(--main-color);
        color: white;
        padding: 10px;
        text-align: center;
    }
}
