.programs {
    &__container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

    }
    &__list {
        // min-height: 100vh;
        min-width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
     flex-wrap: wrap;
        
}
}

.program {

    &__header {

        min-height: 50px;
        text-align: center;
        font-size: 23px;
        line-height: 30px;
        
        
    }
    &__description {
        width: 100%;
        text-align: center;
        position: relative;
        font-size:  20px;
        font-family:Georgia, 'Times New Roman', Times, serif;

        &::before {
            display: flex;
            position: absolute;
            content: '';
            background-color: gray;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            top: -20px;
            left: 30%;
            right: 30%;
            height: 2px;
            width: 40%;
        }
    }
    &__button {
        
            background: var(--radial-gradient);
            color: white;
            height: 60px;
            width: 250px;        
        //  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
        font-stretch: expanded;
         font-size: 20px;

}
}