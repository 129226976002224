.form {
  margin: 0px auto;
  padding: 10px;
  display: flex;
  min-height: 450px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  background-color: var(--main-color);


&__input-container {
  position: relative;
}
&__error-message {
  position: absolute;
  font-size: 14px;
  top:2px;
  color: lightcoral;
  left: 70px;
}
&__wrapper {
    width: 100%;
    min-height: 600px;
    margin: 0px;
     }

  &__inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 270px;
    align-items: center;
    

  }

  &__error {
    color: lightcoral;
    padding: 10px;
    text-align: center;
    font-size: 15px;
  }

  &__personal-info {
    height: 300px;
    background-color: var(--main-color);
    width: 45%;
    padding: 10px;
    &_header {
      
      height: 50px;
      color: lightcoral;
    }
  }
  &__auth-info {
    width: 45%;
    height: 300px;
    padding: 60px 10px 10px;
    background-color: var(--main-color)
  };

  &__header {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-stretch: extra-expanded;
    color: black;
    font-size: 30px;
    padding: 10px;
  }

  &__label {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    padding: 7px;
    left: 5px;
    color: white;

    &.user {}

    &.lock {}
  }

  &__input {

    width: 100%;
    padding: 5px;
    height: 40px;
    border-radius: 3px;
    margin: 5px 0;
    outline: none;
    background-color: white;
    border: 2px solid white;
    padding-left: 45px;
    color: black;
        &.form{
          width: 50%;
        }

    &:focus {
      border: 1px solid lightgray;
      box-shadow: none;
    }
  }

  &__submit {
    width: 50%;
    padding: 5px;
    height: 40px;
    border-radius: 3px;
    margin: 5px 0;
    outline: none;
    border: none;
    color: white;
    text-align: center;
    background-color: var(--main-color);
    font-size: 17px;
    cursor: pointer;
  }

  &__forgot-label {
    font-size: 0.8em;
    color: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    margin-top: 10px;
    padding-top: 10px;
    float: right;
    padding: 3px 0;
  }
  &__checkbox  {
    color: white;
  }


  @include for-extra-small {


    &__error-message {
      position: absolute;
      font-size: 12px;
      top:2px;
      color: lightcoral;
      left: 70px;
    }
    flex-direction: column;
    justify-content: space-between;

     width: 350px;
     min-height: 800px;

    &__wrapper {
      min-height: 1000px;
      margin: 0px;
    }
     &__inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 550px;
     }
     &__submit {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
     }
     &__checkbox {
      width: 90%;

     }

     
  &__personal-info {
    height: 300px;
    width: 100%;
    &_header {
      height: 80px;
      text-align: center;
      color: lightcoral;
    }
  }
  &__auth-info {
    width: 100%;
    height: 230px;
    padding-top: 0px
  
  };


     &__label {
      font-weight: 700;
     }
     &__wrapper {
      width: 350px;
      padding: 0px;
     }
     
     &__input {
      border: 1px solid var(--main-color);
      width: 100%;
     }
  }
}