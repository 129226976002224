@import "../../../../mixins.scss";

.header {
  width: 100%;
  padding-top: 10px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 2;
  border-radius: 0, 5%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  &__logo {
    background-image: url("../../../../images/logo.svg");
    background-repeat: no-repeat;
    background-size: auto;
    width: 90px;
    height: 60px;
  }
  &__burger {
    display: none;
  }
  &__navigation {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__auth {
    &__buttons {
      height: 50px;
      width: 300px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
    }
  }
  @include for-extra-small {
    flex-direction: row;
    align-items: flex-start;

    &__logo {
      width: 70px;
      height: 50px;
    }

    &__navigation {
      display: none;
      width: 100%;
      margin-top: 10px;
      justify-content: flex-start;
      &_link {
        width: 100px;
      }
    }
    &__burger {
      display: flex;
      width: 50px;
      height: 40px;
      font-size: 140%;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    &__auth {
      &_balance {
        width: 50px;
        display: none;
      }
      &__buttons {
        width: 230px;
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
}
