@import "../../../../mixins.scss";

// .footer {
//     padding: 0 60px;
//     position: relative;
//     width: 100%;
//     height: 50px;
//     display: flex;
//     flex-direction: column;
//     margin: auto 0px 0px;
//     background: var(--radial-gradient );
//     color: white;

//     &__header {
//         height: 70px;
//         width: 100%;
//         padding: 5px;
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         align-items: center;
//         // background-color: rgb(160, 133, 160);

//         &_logo {
//             background-image: url('../../../../images/logo.svg');
//             width: 60px;
//             height: 35px;
//             background-repeat: no-repeat;
//             background-size: cover;

//         }

//     }
//     &__images {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         width: 120px;
//         justify-content: space-between;
//     }

//     &__image {
//             width: 70px;
//             height: 30px;
//             background-repeat: no-repeat;
//             background-size: contain;

//         &:first-child {
//             width: 45px;
//             background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/618px-Mastercard-logo.svg.png');
//         }
//         &:nth-child(2) {
//         height: 25px;
//             width: 60px;
//             background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/2560px-Visa_2021.svg.png');
//         }
//     }

//     &__links {
//         padding: 10px 20px;
//         height: 70px;
//         width: 100%;
//         padding: 5px;
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         align-items: center;

//         &_telegram {
//             background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png');
//             background-repeat: no-repeat;
//             background-size: contain;
//             width: 30px;
//             height: 30px;
//         }

//         &_container {
//             min-width: 100px;
//             height: 30px;
//             display: flex;

//             justify-content: space-between;
//         }

//         &_link {
//             margin-left: 20px;
//             width: 30px;
//             height: 30px;
//             background-repeat: no-repeat;
//             background-size: contain;
//             &:first-child {
//                 background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAclBMVEU7V53///8yUZrf5O8jR5aDkr45VZwrTJhFYaTY3eo1U5tqfbNOZqb4+vzJ0ONkebCtuNSJmMFBXaDq7fS7w9t8jbuNnMKksM/l6fLb4OzS1+fGzeC9xdyzvNegrM2TocZbcatwg7Xw8/hUa6kfRZUGOpHhj9NWAAAEF0lEQVR4nO3d6XKbMBSGYSGIJOQYMLbxltRL0vu/xXqJm3baYAER50jzvX8yk5kQnjG7AIvko6IpaxFLdTkr7jBx+1GZTFpFPWPflrIyM9UfwrTU8ejuKV2md2FuJPXseEma/CZMIwUKYU16FZaxAs+fYnkRVpp6Pjymq7PQxLeR+UyZRGwz6rnwWlaIJt618JJsRGmpZ8JrthR1zKvheUWM51AUIYQQQgghhBBCCPnt80KZUpFdNFNWaq3l74ud6vyLy28uSSlt2F51ppjJbLUo8nT+0TpNN/m22L28rn7sZ8vJ4VnoQIc9ldbPs0U6TdqbTk/rraGe2R5JfVilD3CfyufQPkSlbePMC1CotFk9WjZDFipZd/QFJlT6ad3RF5ZQml1nX1BCfTz1AIYjVNm+jy8gYVb1AwYj1C89gaEI9WtfYCBCveoNDEOoZ/2BQQjtoetxTGhC2f1AJiyh7rufCEVoD4OAAQj1JnKhfBoGZC9Utsv5fIjCwR8hd6GS28iFcjIUyF044Ig7DKGq55EL5XIwkLlQL2IXqkHH3AEI1cBD0luchbLbme9pnW+Lf9pxHnvqsBrOF8u32t5HR/+KWtGWdD2t2CyvI8GMF8evcrvIPW20DBB3zr65ASeBDmSfhUcn4ZH1mtaa26a0CvjRQafrwNMQty/3nHYWr+Euo0JkLgOik5Cf/swczu9P1DM5qCx/LMxDXkhF5nBI8xK20OFC4ip64T7kDY2TcBa98AlC1kEIIf8ghJB/EELIPwgh5B+EEPIPQgj5F4dQtfTuJGybAodHu01L0uVqYusULtEa7fBbDx+1ob0oPoJwF72Q+BXIIwiJBzZGEB6jF77RviHYv3BK6htDOCceQfUvzInvJ/Iv3EUvpB7n9y+kHgX3L5wQv07ev5D6Nnbvwin1+aF34Zr6hiLvwm30wkX0wh/RC5fUl+K8C8m/XcW7kHp36F1If4+0b+GG/Gkh38KCelPqXUj/MIZv4T56Ifnu0LuQfHfoW8jgMW7Pwjm1z7swJ98d+hYSj6xdUmWaf93W4TVt65a/33B4oEb97wH6ez8dRrmX7y0TYABsz+1OBeq5HFIcd5u0BSGE/IMQQv5BCCH/IISQfxBCyD8IIeQfhBDyD0II+QchhPyDEEL+QQgh/yCEkH8QQsg/CCHkH4QQ8g9CCPkHIYT8gxBC/kEIIf8ghJB/EELIPwgh5B+EEPIPQgg7VNO8ImQsoaoF0at6xhLaUjQ0C/tYQtmILc0ro8cSZoVIaL7oYyShMokg+pKIkYS6OguTkmJNHEcoy+QiTA3B5nQUoTXpVZjkZvxPcQyhNHlyEyZpqcfe3PgXKl1e/4e4TawyWtoxlX6FykptqttUxH1yRVPW30l4kF9hXTbFfSq/AEUxTy1Jf882AAAAAElFTkSuQmCC');
//             }
//             &:nth-child(2) {
//                 background-image: url('https://icones.pro/wp-content/uploads/2021/02/instagram-logo-icone4.png');
//             }

//         }

//         &_contact-us {
//             display: flex;
//         }
//     }
//     @include for-extra-small {
//         &__links {
//             &_link {
//                 margin-left: 0px;
//                 margin-right: 20px;
//                 }
//         }

//         border: 1px solid darkgray;
//         color: white;
//         font-weight: 700;
//         padding: 20px 0px;
//         height: 280px;
//         &__header {
//         height: 250px;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         align-items: center;
//         padding: 10px;

//         }
//     }
// }

.footer-second {
  &__container {
    background: var(--radial-gradient);
    position: relative;
    width: 100%;
    min-height: 400px;
    box-sizing: border-box;
    padding: 80px;
    display: flex;
    justify-content: space-between;
    @include for-extra-small {
      flex-direction: column;
      padding: 5px;
    }
  }
  &__bottom {
    color: white;
    bottom: 20px;
    position: absolute;
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 20.83px;
    letter-spacing: 0.01em;
    text-align: left;
    @include for-extra-small {
      position: relative;
      margin-top: 20px;
      text-align: center;
    }
  }

  &__logo {
    &_png {
      width: 90px;
      height: 110px;
      background-image: url("./logo.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: 50px;
      top: -60px;
      @include for-extra-small {
        right: 20%;
        top: -30px;
        width: 70px;
        height: 90px;
        display: none;
      }
    }
    &_image {
      font-family: "Questrial";
      font-size: 40px;
      font-weight: 400;
      line-height: 41.2px;
      text-align: left;
      color: white;
    }
    &_description {
      color: white;
      font-size: 18px;
      @include for-extra-small {
        text-align: center;
      }
    }

    &_container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 230px;
      height: 161px;
      @include for-extra-small {
        align-items: center;
        width: 100%;
        margin-top: 20px;
      }
    }

    &_links-container {
      width: 210px;
      display: flex;
      justify-content: start;
      flex-direction: row;
      @include for-extra-small {
        justify-content: center;
      }
    }
    &_link {
      width: 34px;
      height: 34px;
      color: white;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 20px;
      @include for-extra-small {
        margin: 0px;
      }

      &.facebook {
        background-image: url("./facebook.png");
      }
      &.instagram {
        background-image: url("./instagram.png");
      }
      // &.youtube {
      //   background-image: url("./youtube.png");
      // }
      // &.twitter {
      //   background-image: url("./twitter.png");
      // }
      // &.linkdin {
      //   background-image: url("./linkedin.png");
    }
  }

  &__navigation {
    &_container {
      width: 70%;
      min-height: 266px;
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include for-extra-small {
        flex-direction: column;
        width: 100%;
      }
    }
    &_group {
      width: 30%;
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0;
      @include for-extra-small {
        width: 90%;
        justify-content: center;
        align-items: center;
      }
      &.mail {
        width: 315px;
        height: 111px;
        list-style: none;
      }
      :first-child {
        font-family: "DM Sans";
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 24px;
        text-decoration: underline;
        list-style: none;
        color: white;
      }
    }
    &_link {
      font-family: "DM Sans";
      font-size: 18px;
      margin-bottom: 24px;
      font-weight: 400;
      line-height: 23.44px;
      text-align: left;
      list-style: none;
      color: white;
    }
  }
}
