.tests-header {

    &__container {
    margin-top: 50px;
    height: 300px;
    width: 100%;
    background-color: rgb(48, 45, 77);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
&__text {
    margin-top: 10px;
    &:first-child {
        font-size: 5vh;
    }
    &:nth-child(2) {
        font-size: 3.5vh;

    }
    &:nth-child(3) {
        font-size: 3vh;

    }
    &:nth-child(4) {
        font-size: 2.5vh;
    }
}

@include for-extra-small {
    &__container {
        margin-top: 0px;
        padding: 20px;
        height: 400px;
    }
    
    &__text {
        display: flex;
        align-items: center;
        text-align:center;
        justify-content: center;
        margin-top: 10px;
        
        &:first-child {
            font-size: 3vh;
        }
        &:nth-child(2) {
            font-size: 2.5vh;
    
        }
        &:nth-child(3) {
            font-size: 2.5vh;
    
        }
        &:nth-child(4) {
            font-size: 2vh;
        }
    }
}


}