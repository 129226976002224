.popup {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 80px;
    width: 600px;
    min-height: 350px;
    z-index: 3;
    background-color: white;
    
    @include for-extra-small {
        width: 360px;
        border-radius: 0%;
    }
}

.overlay {
    position:absolute;
    width:100%;
    height:100%;
    left:0px;
    opacity: 50%;
    top:0px;
    background: black;
    transition:opacity 1.3s;
     
    //  @include for-extra-small  {
    //     display: none;
    //  }
}
.popup-container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    top:0px;
    position: absolute;
    width: 100%;
    height: 100vh;

}