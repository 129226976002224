@import './Components/course/course.scss';
@import './CoursePage/coursePage.scss';

.courses {
    min-height: 100vh;
 


    &__info {
        background: var(--linear-gradient);
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        color: white;
        position: relative;


    //     &:before {
    //         content: '';
    //         position: absolute;
    //         top: 50px;
    //         height:50px;
    //         width: 50%;
    //         z-index: 1;
    //         background-color: rgb(48, 45, 77);

        
           
    // }
        &:after {
            content: '';
            position: absolute;
            top: 60px;
            height: 200px;
            width: 80%;
            border: 1px solid white;
    }

        &_header {
          font-weight: 700;
          font-size: 2.5em;
          margin: 30px 0;
        
        }
        &_description {
            font-size: 1.5em;
            position: relative;
        
    }
    }


    &__container {
        display: grid;
        grid-template-columns: (1fr 1fr 1fr);
        gap: 20px;
        justify-content: center;
        padding: 100px;
        width:100%;
        }


@include for-extra-small {

    &__info {
        padding: 25px;
        justify-content: space-around;
        height: 250px;
        
        &_header {
            text-align: center;
            display: flex;
            flex-direction: column;
            font-size: 20px;
        }
        &_description {
            font-size: 1em;
            text-align: center;

        }
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
    &__container {
        padding-top: 0px;
        grid-template-columns: (1fr);
    }
}
}