.dropdown-menu {
  position: relative;

  .menu-button {
    /* Стили для кнопки */
    /* ... */

    &:hover {
      background-color: #2980b9;
    }
  }

  .menu-items {
    /* Стили для выпадающего меню */
    /* ... */

    /* Анимация плавного появления */
    animation: fadeIn 0.2s ease-in-out;
  }

  .menu-item {
    /* Стили для элементов меню */
    /* ... */

    &:hover {
      background-color: #f1f1f1;
    }
  }

  &.open .menu-items {
    /* Дополнительные стили для открытого меню */
    /* ... */

    /* Анимация плавного закрытия */
    animation: fadeOut 0.2s ease-in-out;
  }

  /* Ключевые кадры для анимации */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}