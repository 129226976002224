.sorry-form {
  width: 100%;
  background: var(--linear-gradient);
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  &__header {
    width: 100%;
    color: white;
    font-size: 24px;
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    justify-content: center;
    position: relative;

    @include for-extra-small {
      text-align: center;
    }
    &:after {
      width: 80%;
      position: absolute;
      content: "";
      height: 2px;
      background: white;
      bottom: -40px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 150px;
    &_text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: white;
      text-align: center;
    }
    &_contact {
      color: white;
      font-size: 20px;
      text-decoration: underline;
    }
  }
}
