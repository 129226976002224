.tests-main {
    width: 100%;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    padding: 50px;
    align-items: center;
    margin-bottom: 20px;

    &__header {
        font-size: 24px;
        font-weight: 700;
        color: var(--main-color);
        margin-bottom: 30px;
    }

    &__container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 50px 10px;
    }
    &__block {
        max-width: 300px;
        padding: 5px;
        padding-bottom: 10px;
        margin-top: 20px;
        // box-shadow: var(--main-color) 0px 3px 8px;    
        color: white;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: var(--radial-gradient);
        &_header {
            box-shadow: var(white) 0px 3px 8px;  
            font-size: 17px;
            padding: 10px;
            text-align: center;
        margin: 0px;
            font-size: 15px;
        }
        &_description {
            padding: 10px;
            font-size: 14px;
            text-align: center;
        }
        }

        &__button {
            background-color: white;
            padding: 5px;
            margin: 0px 5px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--main-color);

        }
@include for-extra-small {
    flex-direction: column;
    padding: 0px;

    &__container {
        // width: 100%;
        // display: grid;
        // grid-template-columns: repeat(1fr);  
        // gap: 20px;
        // padding: 20px;
        // ;
    }
    &__header {
        padding: 20px;
    }
    &__block {

        width: 100%;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    
        height: 200px;
        display: flex;
        flex-direction: column;
        &_header {
            font-size: 20px;
        }
        &_description {
        }
        }
}
}

.test-main {
    width: 150px;
    font-size: 10px;
}