@import '../Login/loginForm.scss';
@import '../Auth/Components/RegistrationPage/registration.scss';


    .auth {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        &__container {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }
    &__image {
        background-image: url('../../images/cat2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        width: 500px;
        height: 100vh;
    }

    @include  for-extra-small {
        &__container {
            margin-bottom: 20px;
            width: 100%;
        }
    }
}