.article {
    width: 100%;
    min-height: 100vh;
display: flex;
flex-direction: column;
    &__container {
        margin: 0 auto;
        width: 80%;
        
    }
    &__description {
        padding: 40px;
        text-align: center;
        opacity: 70%;

    }
    &__header {

        min-height: 70px;
        padding: 20px;
        text-align: center;
        font-size: 30px;
        margin-top: 30px;
        border-bottom: 5px solid lightgray;

    }
    &__paragraph {
        line-height: 40px;
        margin-top: 10px;
        text-indent: 40px;
        text-align: justify;
    }
    
    &__section-header {
        font-size: 30px;
        font-weight: 700;
    }

    &__programs {


        width: 100%;
        margin: 0 auto;
        &_container {

        }
    }
    &__program {
        border-bottom: 3px solid lightgray;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        &:nth-child(2n) {
            flex-direction: row-reverse;
        }

        &_image {
            color: lightgray;
            width: 10%;
            height: 200px;
            font-size: 150px;
            display: flex;
            justify-content: start;
            align-items: start; 
            line-height: 150px;

        }
        &_container{
            width: 80%;
        }
        &_header {
            padding: 10px;
            font-size: 20px;
            font-weight: 600;
            text-align: justify;
        }
        &_text { 
            text-indent: 40px;
            font-weight: 200;
            padding: 20px;
            line-height: 30px;
            text-align: justify;

        }
        &__img {
            margin: 40px auto;
            width: 80%;
            height: 800px;
        }
    }
    @include for-extra-small {
        &__container {
            width: 95%;
        }
        &__description {
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 10px;
        }
        &__paragraph {
            font-size: 13px;
            padding: 0px 15px;
            line-height: 20px;
            margin-top: 0px;
            margin-bottom: 20px;
        }

        &__programs {
            &_container { 
                width: 100%;
                padding: 0px;
                margin-top: 0px;
                
            }
        }
        &__section-header {
            margin-bottom: 20px;
            font-size: 22px;
        }
        
        &__program {
            flex-direction: column;
            &:nth-child(2n) {
                flex-direction: column;
            }
            &_text{
                width: 100%;
                font-size: 13px;
                line-height: 20px;
            }
            &_header {
                font-size: 20px;
                text-align: center;
            }
            &_image {
                font-size: 50px;
                width: 100%;
                align-items: center;
                justify-content: center;
                height: 50px;
            }
            &_container{
                padding: 0px;
                width: 100%;
            }
        }

        &__img {
            height: 340px;
        }
    }

}