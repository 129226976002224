@import "../../../../mixins.scss";

.information {
  margin-bottom: 50px;
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  color: white;
  background: var(--radial-gradient);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  &__main {
    width: 80%;
    min-height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &_text {
      margin-top: 30px;
      font-size: 25px;
      font-weight: 100;
      text-align: center;

      &:first-child {
        position: relative;
        &:before {
          margin-top: 45px;
          content: "";
          height: 2px;
          width: 50%;
          left: 25%;
          right: auto;
          top: 40px;
          position: absolute;
          background: white;
          display: inline-block;
        }
      }
    }
  }

  &__statistics {
    margin-top: 40px;
    width: 100%;
    height: 300px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    &_block {
      background-color: white;
      padding: 0px;
      width: 48%;
      height: 150px;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      &_number {
        padding: 5px;
        font-size: 50px;
        width: 20%;
        color: var(--main-color);
        text-align: center;
      }
      &_text {
        font-size: 14px;
        width: 80%;
        padding: 20px 10px 20px 15px;
        position: relative;
        text-align: initial;
        color: var(--main-color);
        &::before {
          width: 2px;
          height: 80%;
          content: "";
          position: absolute;
          background-color: var(--main-color);
          left: 0;
          top: 10%;
        }
      }
    }
  }

  @include for-extra-small {
    height: 1000px;
    padding: 0px;

    &__main {
      width: 100%;
      padding: 20px;
      height: 120px;

      &_text {
        text-align: center;
        font-size: 15px;
        line-height: 25px;

        &:first-child {
          position: relative;
          &:before {
            display: none;
          }
        }
      }
    }

    &__statistics {
      background: var(--radial-gradient);
      height: 800px;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 30px 20px;

      &_block {
        background-color: white;
        padding: 0px;
        width: 100%;
        height: 150px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        &_number {
          padding: 5px;
          font-size: 50px;
          width: 20%;
          color: var(--main-color);
          text-align: center;
        }
        &_text {
          font-size: 14px;
          width: 80%;
          padding: 20px 10px 20px 15px;
          position: relative;
          text-align: initial;
          &::before {
            width: 2px;
            height: 80%;
            content: "";
            position: absolute;
            background-color: var(--main-color);
            left: 0;
            top: 10%;
          }
        }
      }
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
