@import './infoBlock.scss';

.course-page {
    position: relative;
    &__container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
    &__main {
        width: 100%;
    }
    &__info {   
        width: 100%;
        height: 600px;
        display: flex;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        flex-direction: column;
       
        &_container {
            width: 100%;
            height: 500px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        &_blocks {
            height: 300px;
            width: 80%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);  
            gap: 20px;
            padding: 20px 0px 20px;
        }
        &_block {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
            background: var(--radial-gradient);
            color: white;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
    
        &_text {
            height: 50px;
            margin: 0px;
            font-weight: 500;
            font-size: 24px;
        }
        &_header {
            width: 60%;
            padding: 40px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
        }
    &_image {
        background-image: url('../../../images/bigLogo.svg');
        background-size: 90%;
        background-position: center;
        background-repeat: no-repeat;
        background-position-y: 30px;
        width: 40%;
        height: 100%;
        background-repeat: no-repeat;
    }
        
    }
    &__more-info {
        &_header {
            margin: 40px 0px;
            width: 100%;
            text-align: center;
            font-size: 20px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
    }

    &__button {
        width: 80%;
        background: var(--linear-gradient);
        border: none;
        color: white;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 40px;

    };


    @include for-extra-small {
        &__main {
        width: 100%;
        min-height: 100vh;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        }
        &__info {   
            width: 360px;
            &_container {
                flex-direction: column;
                justify-content: space-around;
            }
            &_blocks {
                width: 100%;
            }
            &_image {
                display: none;
            }
            &_text {
                height: 70px;
                text-align: center;
                font-size: 20px;
            }
            &_header {
                width: 100%;
                padding: 10px;
                justify-content: center;
                align-items: center;
            }
            &_block {
                height: 100px;
                padding: 5px;
            }
    }
    &__more-info {
        margin-bottom: 30px;
    }
}

@include for-small {
    &__info {
        width: 600px;
        &_text {
            font-size: 24px;
            font-weight: 600;
        }
    }
}

@include for-large {
    &__info {
        width: 800px;
    }
}
}

.course-button {
    width: 200px;
    display: flex;
    justify-content: center;    
    align-items: center;
    font-size: 14px;
    &__bottom {
        margin-bottom: 30px;
        width: 200px;
        align-self: center;
    }
    
}


.popup-anchor { 
    // z-index: 3;
    position: relative;
    width: 100%;
    height: 1px;
    background-color: green;
    &__container {
    
            display: flex;
            position: sticky;
            z-index: 3;
            top: 0px;
            width: 100%;
            height: 100%;

    }
}