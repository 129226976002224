@import '../mixins.scss';

.button {
  appearance: none;
  backface-visibility: hidden;
  background: var(--radial-gradient);
  border-style: none;
  box-shadow: none;
  line-height: 1.3;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  letter-spacing: normal;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &.test {
    max-height: 50px;
    display: flex;
    align-items: center;
    margin: 0px;
    justify-content: center;
    text-align: center;
  }
  &.button-header {
    padding: 0px;
    padding-left: 1.4em;
    padding-right: 1.4em;
    }


  @include for-extra-small {
    font-size: 10px;
    &.test {
      height: 50px;
      max-height: 50px;
      display: flex;
      align-items: center;
      margin: 0px;
      justify-content: center;
      text-align: center;  
      font-size: 15px;
      
    }
  
    min-width: 80px;
  }

}

.button:hover {
  background-color: rgba(27, 20, 60);
  box-shadow: rgba(51, 52, 100, 0.05) 0 5px 30px, rgba(0, 0, 0, .05) 0 1px 4px;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.button:hover:after {
  opacity: .5;
}

.button:active {
  box-shadow: rgba(0, 0, 0, .1) 0 3px 6px 0, rgba(0, 0, 0, .1) 0 0 10px 0, rgba(0, 0, 0, .1) 0 1px 4px -1px;
  transform: translateY(2px);
  transition-duration: .35s;
}

.button:active:after {
  opacity: 1;
}




@media (min-width: 768px) {
  .button {
    padding: 14px 22px;
    width: 150x;
  }
}

