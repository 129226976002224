.overlay {
    position:absolute;
    width:100%;
    height:100%;
    left:0px;
    opacity: 50%;
    top:0px;
    background: black;
    transition:opacity 1.3s;
}


.warning {
    position: absolute;
    width: 100%;
    min-height: 100vh;

    &__container {
        position: absolute;
        width: 100%;
        min-height: 100vh;
        // background-color: gray;
        z-index: 3;
        display: flex;

    }

    &__text {
        text-align: justify;
        padding: 40px;
        font-size: 16px;
        line-height: 25px;
    }

    &__main {
        width: 440px;
        height: 250px;
        margin: 65px auto;
        padding: 20px; 
        border-radius: 4%;
        opacity: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
&__buttons {
    display: flex;
    justify-content: end;
    width: 60%;
    margin-left: auto;

    justify-content: space-between;
}
&__button_close {
    position: absolute;
    cursor: pointer;
    font-size: 20px;
    right: 15px;
    top: 5px;
}

}