@import '../Tests/Components/TestsHeader/tests-header.scss';
@import '../Tests/Components/Test/test.scss';
@import '../Tests/TestPage/testpage.scss';

.tests {
    padding: 0px;
    min-height: 100vh;

    &__container {
        padding: 50px 20px;
        width: 100%;
        min-height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}