@import "../../../mixins.scss";
@import "../components/error.scss";

.input {
  width: 200px;
  height: 30px;
  position: relative;
}

.certificate {
  &__inputs {
    height: 200px;
    max-width: 600px;
    justify-content: space-around;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    @include for-extra-small {
      flex-direction: column;
      flex-wrap: nowrap;
      height: 200px;
    }
  }

  &__button {
    margin-top: 10px;
    width: 200px;
    height: 40px;
    background: var(--linear-gradient);
    color: white;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    &.disabled {
      opacity: 50%;
    }
  }
}

.certificates {
  &__container {
    // min-height: 100vh;
    // display: flex;
    // flex-direction: column;
    // justify-items: start;
    // align-items: start;
  }
}
.certificate-list {
  &__container {
    padding-bottom: 50px;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  &__header {
    text-align: center;
  }
  &__table {
    width: 90%;
    display: flex;
  }
}
.certificate-item {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-top: 20px;
    // border: 1px solid transparent;

    margin: 20px 0;
    border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    @include for-extra-small {
      flex-direction: column;
      margin: 20px 0;
      border: 1px solid black;
    }
    @include for-extra-small {
      flex-direction: column;
    }
  }

  &__text {
    min-width: 17%;
    text-overflow: ellipsis;
    padding: 10px;
    flex-wrap: wrap;
    flex-grow: 0;
    text-align: center;
    @include for-extra-small {
      text-align: center;
    }

    &:first-child {
      background: var(--linear-gradient);
      color: white;
      min-width: 30%;
      // max-width: 30%;
      max-width: 100%;

      @include for-extra-small {
        max-width: 100%;
      }
    }
    &:nth-child(3) {
      background: var(--linear-gradient);
      color: white;
      @include for-extra-small {
        background-color: unset;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &:nth-child(5) {
    }
  }
}
