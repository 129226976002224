@import './carouselCard.scss';

.carousel {
    width: 100%;
    min-height: 450px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__container {
        margin-top: 20px;
        border-radius: 2px;
        width: 100%;
        margin-bottom: 40px;
        padding: 4px;
        box-shadow:  rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    }
    &__header {

        width: 100%;
        text-align: center;
        margin: 50px 0;
        font-size: 25px;
        padding: 20px;
        font-weight: 500;
        line-height: 40px;
        background: var(--radial-gradient);
        color: white;
    }
    // &__card {
    //     border: 1px solid black;
    //     border-radius: 3%;
    //     margin: 10px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    // }
    @include for-extra-small {

        &__header {
            text-align: center;
        }

        min-height: 750px;
}

}