.about {
  width: 100%;
  margin-top: 10px;
  min-height: 550px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;

  // background-image: url('../../images/blurred-corridor-with-chairs-tables.jpg');
  &__background {
    background-image: url("./img_1.png");
    background-size: contain;
    @include for-extra-small {
      background-image: none;
    }
    z-index: -2;
    &.left {
      width: 500px;
      height: 500px;
      position: absolute;
      left: -100px;
      opacity: 20%;
      top: 300px;
      transform: rotate(60deg);
    }
    &.right {
      width: 500px;
      height: 500px;
      position: absolute;
      right: -100px;
      opacity: 20%;
      top: -200px;
      transform: rotate(-120deg);
    }
  }

  &__info {
    width: 50%;
    height: 400px;
    border-radius: 2%;
    display: flex;
    flex-direction: column;
    padding-top: 27px;

    &_header {
      margin-left: 50px;
      font-size: 34px;
      color: var(--main-color);
    }

    &_text {
      background: var(--linear-gradient);
      padding-left: 5px;
      color: white;
      height: 30px;
      display: flex;
      text-align: start;
      align-items: center;
      margin-top: 30px;
      margin-left: 50px;
      &:nth-child(2) {
        color: rgb(48, 45, 77);
        background: unset;
      }
    }
  }

  &__navigation {
    width: 40%;
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10px;
    padding: 40px;
    height: 400px;

    &_block {
      &:nth-child(1) {
        font-size: 0px;
        content: "";
        background: unset;
      }
      height: 70%;
      color: white;
      font-weight: 100;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      background: var(--radial-gradient);
    }
  }

  @include for-extra-small {
    flex-direction: column;

    &__info {
      width: 100%;
      height: 500px;
      border-radius: 2%;
      display: flex;
      flex-direction: column;
      padding-top: 0px;
      margin-left: 0px;
      position: relative;

      &_img {
        position: absolute;
        width: 100px;
        height: 130px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        left: 10px;
        top: 20px;
        background-image: url("../../../../images/logoNew.svg");
      }

      &_header {
        text-align: center;
        align-self: flex-end;

        font-size: 35px;
        margin: 20px 0;
        color: var(--main-color);
        width: 70%;
        padding: 10px;
      }

      &_text {
        background: var(--linear-gradient-reverse);
        height: 60px;
        color: white;
        width: 70%;
        margin: 0px;
        margin-left: 5px;
        padding: 5px;
        border-radius: 4px;
        text-align: start;
        &:first-child {
          background: var(--linear-gradient);
          align-self: end;
          margin-right: 5px;
          margin-left: auto;
          margin-bottom: 20px;
        }
        &:nth-child(2) {
          align-self: end;
          color: white;
          background: var(--linear-gradient-reverse);
          margin-bottom: 20px;
        }
        &:nth-child(3) {
          align-self: end;
          background: var(--linear-gradient);
          margin-right: 5px;
          margin-left: auto;
          color: white;
          margin-bottom: 20px;
        }
      }
    }

    &__navigation {
      width: 100%;
      display: flex;
      align-self: start;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      gap: 0px;
      padding: 10px;
      flex-wrap: wrap;
      margin: 0px;
      align-items: center;
      height: 400px;

      &_block {
        border-radius: 0px;
        color: white;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: 100;
        font-size: 20px;
        padding: 10px;
        background: radial-gradient(
          circle,
          rgba(96, 107, 162, 1) 0%,
          rgba(42, 44, 77, 1) 100%
        );
        width: 40%;
        height: 160px;

        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          position: relative;
          width: 320px;
        }
      }
    }
  }

  @include for-small {
    width: 590px;
  }
  @include for-large {
    width: 700px;
    margin: 0px auto;
    box-shadow: none;
  }
}
