.error {
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__overlay {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    background: var(--main-color);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 100%;
  }
  &__container {
    position: absolute;
    width: 500px;
    height: 250px;
    z-index: 1;
    background: white;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid var(--main-color);
    @include for-extra-small {
      width: 370px;
    }
    &_header {
      font-size: 19px;
      font-family: Arial, Helvetica, sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -20px;
        width: 80%;
        height: 3px;
        background: var(--linear-gradient);
      }
    }
    &_text {
      font-size: 15px;
      font-family: Arial, Helvetica, sans-serif;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &_close-button {
      position: relative;
      justify-self: end;
      width: 20px;
      height: 20px;
      left: 50%;
      top: -10px;
      font-weight: 700;
      color: var(--linear-gradient);
      text-align: center;
    }
    &_ok-button {
      margin-top: 10px;
      width: 200px;
      height: 30px;
      background: var(--linear-gradient);
      color: white;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
  }
}
