.legal-info {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__header {
    margin: 0px;

    &_container {
      width: 100%;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      margin-top: 20px;
      background: var(--radial-gradient);

      @include for-extra-small {
        height: 400px;
        justify-content: space-around;
      }
    }

    &_description {
      position: relative;
      width: 100%;
      height: 70px;
      display: flex;
      font-size: 18px;
      align-items: center;
      justify-content: center;
      @include for-extra-small {
        height: 150px;
        text-align: center;
      }
      &::before {
        background: white;
        width: 80%;
        height: 3px;
        content: "";
        position: absolute;
        top: 0px;
      }
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__adress {
    background: var(--radial-gradient);
    height: 200px;
    width: 100%;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_header {
      color: white;
      font-size: 25px;
      font-weight: 500;
    }
    &_text {
      width: 80%;
      color: white;
      padding: 10px;
      text-align: center;
    }
  }

  &__contacts {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    margin: 0px;

    &_header {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-weight: 500;
      color: var(--main-color);
    }
    &_container {
      width: 70%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      @include for-extra-small {
        width: 100%;
      }
    }

    &_contact {
      background: var(--radial-gradient);
      width: 300px;
      margin: 10px 50px;
      padding-left: 50px;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      height: 50px;
      box-sizing: border-box;

      @include for-extra-small {
        padding-left: 70px;
        padding-right: 10px;
        text-align: center;
      }

      &.instagram {
        position: relative;

        &::before {
          content: "";
          width: 30px;
          height: 30px;
          position: absolute;
          background-image: url("./icons/Instagram_icon.png");
          background-repeat: no-repeat;
          background-size: contain;
          left: 20px;
        }
      }
      &.facebook {
        position: relative;

        &::before {
          content: "";
          width: 30px;
          height: 30px;
          position: absolute;
          background-image: url("./icons/Facebook_Logo.png");
          background-repeat: no-repeat;
          background-size: contain;
          left: 20px;
        }
      }
      &.mail {
        position: relative;
        &::before {
          content: "";
          width: 30px;
          height: 30px;
          position: absolute;
          background-image: url("./icons/mail_icon.png");
          background-repeat: no-repeat;
          background-size: contain;
          left: 20px;
        }
      }
      &.phone {
        position: relative;
        &::before {
          content: "";
          width: 30px;
          height: 30px;
          position: absolute;
          background-image: url("./icons/phone_icon.png");
          background-repeat: no-repeat;
          background-size: contain;
          left: 20px;
        }
      }
    }
  }
  &__header {
    width: 100%;
    height: 100px;
    font-size: 35px;
    text-align: center;
    padding: 20px;
  }
  &__types {
    width: 100%;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    height: 50%;
    @include for-extra-small {
    }

    &_header {
      width: 100%;
      text-align: center;
      height: 50px;
      font-size: 24px;
      color: var(--main-color);
    }
    &_type {
      height: 50px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      margin-top: 30px;
      position: relative;
      @include for-extra-small {
        max-width: 300px;
      }
      &::before {
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        background: var(--radial-gradient);
        position: absolute;
        border-radius: 100%;
        content: "";
        left: -30px;
        top: 17px;
      }
    }
  }
}
